<template>
  <b-card>
    <b-row class="mt-2">
      <div class="mb-1">
        <b-button class="float-left" variant="primarySpore" @click="back()">Regresar</b-button>
      </div>
    </b-row>
    <div>
      <b-row class="mt-1">

        <b-col md="12" lg="3">
          <b-card class="card_statisc">
            <b-row>
              <b-col md="12" v-if="response.cfeCabinet">
                <h4>Número Gabinete: </h4>
                <label class="font-medium-1 mb-1">{{response.cfeCabinet.number}}</label>
                <h4>Serie: </h4>
                <label class="font-medium-1 mb-1">{{response.serie}}</label>
                <h4>Agencia: </h4>
                <label class="font-medium-1">{{response.cfeAgency.name}}</label><br>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="12" lg="3">
          <b-card class="card_statisc">
              <b-row>
                <b-col md="12">
                  <h4>Fecha Instalación: </h4>
                  <label class="font-medium-1 mb-1">{{ response.installedAt }}</label>
                </b-col>
                <b-col md="12" class="mb-1">
                  <h4>Fecha Ultima transmisión: </h4>
                  <label class="font-medium-1">{{ response.transmitedAt }}</label>
                </b-col>
                <b-col md="12" class="mb-1">
                  <h4>Señal: </h4>
                  <icon-signal-percent class="align-middle" width="25" height="25" iconColor="#000" :percent="parseInt(response.sign)" />
                  <span class="align-middle">
                    {{ getSignalPercent(response.sign) }}
                  </span>
                </b-col>
              </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" md="12">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <label>Mostrar</label>
                <v-select v-model="filters.limit" :options="this.perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @input="getDeviceCommands"/>
                <label>Registros</label>
              </b-col>
            </b-row>
          </div>

          <b-table ref="refUserListTable" class="position-relative" :items="this.responseTable" :fields="this.tableColumns" primary-key="id" show-empty empty-text="No hay registros">
            <template #head(invoiceStatus)>
              <feather-icon
                icon="TrendingUpIcon"
                class="mx-auto"
              />
            </template>

            <template #cell(type)="data">
              <b-row>
                <b-col cols="12" md="12" class="custom-search d-flex justify-content-left">
                  <div class="text-left">
                    <listItem :value="(data.item.type === 'M' ? 'Estatus Relay' : data.item.type === 'CS' ? 'Lectura / Mantenimiento / Reinicio' : data.item.type === 'CT' ? 'Tiempo Reporteo' : '')" description="Descripción" img="alias"/>
                  </div>
                </b-col>
              </b-row>
            </template>

            <template #cell(status)="data">
              <b-row>
                <b-col cols="12" md="12" class="custom-search d-flex justify-content-left">
                  <div class="text-left">
                    <listItem :value="(data.item.status === 'P' ? 'Pendiente' : data.item.status === 'T' ? 'Transmitido' : '')" description="Descripción" img="alias"/>
                  </div>
                </b-col>
              </b-row>
            </template>

           <template #cell(command)="data">
            <div class="text-left">
              {{ data.item.command }}
            </div>
          </template>

          <template #cell(createdAt)="data">
            <div class="text-center">
              {{ format(data.item.createdAt) }}
            </div>
          </template>

          <template #cell(transmitedAt)="data">
            <div class="text-center">
              {{ format(data.item.transmitedAt) }}
            </div>
          </template>

          </b-table>

          <div class="mx-2 mb-2">
            <b-row>

              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <!--<span class="text-muted">Showing {{ count }} of {{ totalRows }} entries</span>-->
              </b-col>
              <!-- Pagination -->
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                <b-pagination v-model="currentPage" :total-rows="this.totalRows" :per-page="this.filters.limit" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18"/>
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18"/>
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-col>
        <br><br>
      </b-row>
    </div>

  </b-card>
</template>

<script>
// import { empty } from '@/libs/tools/helpers'
import { formatDateLanguages } from '@/libs/tools/helpers'
import listItem from '@core/spore-components/listItem/listItem.vue'
import iconSignalPercent from '@core/spore-components/icons/iconSignalPercent.vue'
import {
  BButton, BRow, BCol, BCard, VBTooltip, BTable, BPagination
} from 'bootstrap-vue'
import request from '@/libs/request/index'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BTable,
    BPagination,
    listItem,
    iconSignalPercent
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      uuid: this.$route.params.uuid,
      response: [],
      tableColumns: [
        { thClass: 'text-left', key: 'status', sortable: true, label: 'Estatus' },
        { thClass: 'text-left', key: 'type', sortable: true, label: 'Descripción' },
        { thClass: 'text-left', key: 'command', sortable: true, label: 'Comando' },
        { thClass: 'text-center', key: 'createdAt', sortable: true, label: 'Fecha de Creación' },
        { thClass: 'text-center', key: 'transmitedAt', sortable: true, label: 'Fecha de Transmisión' }
      ],
      totalRows: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 75, 100],
      perPage: 25,
      count: 0,
      responseTable: [],
      file: '',
      avatar: '',
      filters: {
        limit: 10,
        offset: 0,
        byColumn: 'id',
        byOrder: 'ASC',
        deviceUuid: this.uuid
      }
    }
  },
  methods: {
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    getSignalPercent (value) {
      value = parseInt(value)
      if (value === 99) return 'No conocido o no detectado'
      else if (value > 30) return '-52 dBm o muy buena'
      else if (value > 1 && value <= 30) return '-110... - 54 dBm'
      else if (value === 1) return '-111 dBm'
      else if (value === 0) return '-115 dBm o menor'
      return value
    },
    async unassingTeam (installer) {
      const params = {
        url: `users/assignTeam/${installer}`,
        method: 'PUT',
        params: {
          teamUuid: null
        }
      }
      await request(params).then(data => {
        this.getDeviceCommands()
      })
    },
    add () {
      this.$router.push(`/teams/${this.uuid}/addmembers`)
    },
    async getDetailDevice () {
      const params = {
        url: `/devices/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        if (data.data.code === 200) {
          this.response = data.data.data
        }
      })
    },
    async getDeviceCommands () {
      const params = {
        url: '/commands',
        method: 'GET',
        params: this.filters
      }
      await request(params).then(data => {
        if (data.data.code === 200) {
          this.responseTable = data.data.data.rows
          this.totalRows = data.data.data.count
          this.count = data.data.data.rows.length
        }
      })
    },
    back () {
      this.$router.push(`/gabinets/${this.uuid}/detail`)
    }
  },
  created () {
    this.getDetailDevice()
    this.getDeviceCommands()
  },
  watch: {
    currentPage (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getDeviceCommands()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
