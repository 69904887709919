<template>
  <div class="device-info">
      <div class="container-img" v-if="img != undefined">
        <b-img :src="require(`@/assets/images/spore-icons/${img}.svg`)" class="info-icon"/>
      </div>
      <div class="device-text-container" v-if="!relativeTime">
        <span class="main-info" :class="classValue">{{value}}<span class="unit">{{unit}}</span></span>
        <span class="subtext-info" :class="classDescription">{{description}}</span>
      </div>
      <div class="device-text-container" v-if="relativeTime">
        <span class="main-info">{{relativeTimeConvert(value)}}</span>
        <span class="subtext-info" :class="classDescription" v-if="description.length > 0">{{description}}</span>
      </div>
  </div>
</template>
<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg
  },
  props: {
    img: String,
    value: String,
    unit: String,
    description: String,
    relativeTime: Boolean,
    classValue: String,
    classDescription: String
  },
  methods: {
    relativeTimeConvert (date) {
      date.replace(' ', 'T')
      return this.$dayjs().to(this.$dayjs(date))
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/spore-components/listItem.scss";
</style>
